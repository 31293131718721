import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { loginRequest } from '../../authConfig';
import { allRoutes } from '../../const/routes';
import { useSsoUserGroupAccess } from '../../hooks/useUserGroupAccess';
import type { SsoUserGroup } from '../../types';

interface RouteGuardProps extends PropsWithChildren {
  ssoUserGroups?: SsoUserGroup[];
}

export const RouteGuard = ({
  children,
  ssoUserGroups = [],
}: RouteGuardProps) => {
  const { defaultPage, isDefaultPage, currentAccess } =
    useSsoUserGroupAccess(ssoUserGroups);
  // const [hasAccess, availableGroups] = useSsoUserGroupAccess(ssoUserGroups);
  const { pathname } = useLocation();
  let redirectRoute;
  if (ssoUserGroups.length) {
    if (currentAccess && isDefaultPage && pathname !== defaultPage) {
      redirectRoute = defaultPage;
    }
    if (currentAccess && pathname === allRoutes.NO_ACCESS && defaultPage) {
      redirectRoute = defaultPage;
    }

    if (!currentAccess && defaultPage) {
      redirectRoute = defaultPage;
    }
    if (!currentAccess && !defaultPage && pathname !== allRoutes.NO_ACCESS) {
      redirectRoute = allRoutes.NO_ACCESS;
    }
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      {redirectRoute ? <Navigate to={redirectRoute} /> : children}
    </MsalAuthenticationTemplate>
  );
};
