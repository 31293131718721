// import utc from 'dayjs/plugin/utc' // ES 2015

import {
  DateRange,
  NonEmptyDateRange,
  PickersShortcutsItem,
} from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs, extend, type ManipulateType } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  createContext,
  PropsWithChildren,
  useReducer,
  Dispatch,
  useMemo,
} from 'react';

import { useQueryStatsAircrafts } from '../../../adapters';

export interface IHistAircraft {
  Registration: string;
  Id: number;
}
extend(utc);
extend(timezone);
const tz = 'America/New_York';
dayjs.tz.setDefault(tz);

const shortcutFormulasMap: Record<ShortcutKey, [number, ManipulateType]> = {
  lastWeek: [6, 'day'],
  lastMonth: [1, 'month'],
  last3Months: [3, 'month'],
  last6Months: [6, 'month'],
  lastYear: [1, 'year'],
};
interface StatsShortcutsItem<P extends string = ShortcutKey>
  extends PickersShortcutsItem<DateRange<Dayjs>> {
  id: P;
  periodDefinition: [number, ManipulateType];
}

const today = dayjs().tz().hour(0).minute(0).second(0).millisecond(0);
type ShortcutKey =
  | 'lastWeek'
  | 'lastMonth'
  | 'last3Months'
  | 'last6Months'
  | 'lastYear';

const shortcutItems: StatsShortcutsItem[] = [
  {
    id: 'lastWeek',
    label: 'Last Week',
    getValue: () => [
      today.subtract(...shortcutFormulasMap.lastWeek).subtract(1, 'day'),
      today.subtract(1, 'day'),
    ],
    periodDefinition: shortcutFormulasMap.lastWeek,
  },
  {
    id: 'lastMonth',
    label: 'Last Month',
    getValue: () => [
      today.subtract(...shortcutFormulasMap.lastMonth),
      today.subtract(1, 'day'),
    ],
    periodDefinition: shortcutFormulasMap.lastMonth,
  },
  {
    id: 'last3Months',
    label: 'Last 3 Months',
    getValue: () => [
      today.subtract(...shortcutFormulasMap.last3Months),
      today.subtract(1, 'day'),
    ],
    periodDefinition: shortcutFormulasMap.last3Months,
  },
  {
    id: 'last6Months',
    label: 'Last 6 Months',
    getValue: () => [
      today.subtract(...shortcutFormulasMap.last6Months),
      today.subtract(1, 'day'),
    ],
    periodDefinition: shortcutFormulasMap.last6Months,
  },
  {
    id: 'lastYear',
    label: 'Last Year',
    getValue: () => [
      today.subtract(...shortcutFormulasMap.lastYear),
      today.subtract(1, 'day'),
    ],
    periodDefinition: shortcutFormulasMap.lastYear,
  },
];

export interface IStatsState {
  aircrafts: IHistAircraft[];
  selectedAircrafts: IHistAircraft['Id'][];
  shortcuts: StatsShortcutsItem[];
  dateRange: NonEmptyDateRange<Dayjs>;
  activeShortcut?: StatsShortcutsItem;
}
const initialDateTo = today;

const initialDateFrom = initialDateTo.subtract(10, 'day');
const initialDateRange: NonEmptyDateRange<Dayjs> = [
  dayjs(initialDateFrom),
  dayjs(initialDateTo),
];

const contextDefault: IStatsState = {
  dateRange: initialDateRange,
  shortcuts: shortcutItems,
  aircrafts: [],
  activeShortcut: shortcutItems[1],
  selectedAircrafts: [],
};

export const StatsContext = createContext(contextDefault);
export const StatsDispatchContext = createContext(
  null as unknown as Dispatch<TStatsAction>,
);

export type TStatsAction =
  | { type: 'setRange'; payload: NonEmptyDateRange<Dayjs> }
  | {
      type: 'setActiveShortcut';
      payload?: StatsShortcutsItem;
    }
  | {
      type: 'setSelectedAircrafts';
      payload?: IHistAircraft['Id'][];
    };

export const statsReducer = (state: IStatsState, action: TStatsAction) => {
  switch (action.type) {
    case 'setRange':
      return { ...state, dateRange: action.payload };
    case 'setActiveShortcut':
      return { ...state, activeShortcut: action.payload };
    case 'setSelectedAircrafts':
      return { ...state, selectedAircrafts: action.payload ?? [] };
    default:
      return state;
  }
};

export const StatsProvider = ({ children }: PropsWithChildren) => {
  const { data = [] } = useQueryStatsAircrafts();
  const [statsState, dispatch] = useReducer(statsReducer, contextDefault);
  const newValue = useMemo(
    () => ({ ...statsState, aircrafts: data }),
    [data, statsState],
  );
  return (
    <StatsContext.Provider value={newValue}>
      <StatsDispatchContext.Provider value={dispatch}>
        {children}
      </StatsDispatchContext.Provider>
    </StatsContext.Provider>
  );
};
