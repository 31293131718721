import {
  Update as UpdateIcon,
  HistoryToggleOff as HistoryToggleOffIcon,
  ManageHistory as ManageHistoryIcon,
  ScheduleSend as ScheduleSendIcon,
  RunningWithErrors as RunningWithErrorsIcon,
  MoreTime as MoreTimeIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import {
  Box,
  List,
  ListItem,
  styled,
  listClasses,
  listItemClasses,
  Link,
  linkClasses,
} from '@mui/material';
import { NavLink } from 'react-router-dom';

import { ReactComponent as ComboPageBgSVG } from '../../assets/comboPageBg.svg';
import { ReactComponent as ComboPageSVG } from '../../assets/comboPageIcon.svg';
import { ReactComponent as HubSVG } from '../../assets/hub.svg';
import { ReactComponent as MainMapSVG } from '../../assets/mainMapIcon.svg';
import { ReactComponent as MonitorMenuSVG } from '../../assets/monitorMenuIcon.svg';
import { ReactComponent as PlaneMenuSVG } from '../../assets/planeMenuIcon.svg';
import { ReactComponent as PlannerMenuSVG } from '../../assets/plannerMenuIcon.svg';
import { ReactComponent as StatisticsSVG } from '../../assets/statistics.svg';
import { ReactComponent as StatsLogoSVG } from '../../assets/statsLogo.svg';
import { ReactComponent as TransmedicsLogoSVG } from '../../assets/transmedicsLogo.svg';
import { ReactComponent as WeatherSVG } from '../../assets/weather.svg';
import { Loader } from '../../components';
import { allRoutes as routes } from '../../const/routes';
import { useSsoUserGroupAccess } from '../../hooks/useUserGroupAccess';

const PREFIX = 'TSMED-StartPage';

const startPageClasses = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  bg: `${PREFIX}-bg`,
  bgLines: `${PREFIX}-bgLines`,
  bgLogo: `${PREFIX}-bgLogo`,
  bgText: `${PREFIX}-bgText`,
  menuContainer: `${PREFIX}-menuContainer`,
  menu: `${PREFIX}-menu`,
  menuHeader: `${PREFIX}-menuHeader`,
  menuContent: `${PREFIX}-menuContent`,
  menuList: `${PREFIX}-menuList`,
  menuLink: `${PREFIX}-menuLink`,
  logoutLink: `${PREFIX}-logoutLink`,
};

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme: { spacing, breakpoints, palette } }) => ({
  [`&.${startPageClasses.root}`]: {
    position: 'relative',
    display: 'flex',
    gap: spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000',
    minHeight: '100vh',
  },
  [`.${startPageClasses.bg}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
    width: '100vw',
    height: '100vh',
    [`.${startPageClasses.bgLogo}`]: {
      position: 'absolute',
      top: 40,
      left: 51,
      color: '#1d2a37',
      ['& > svg']: {
        width: 48,
      },
    },
    [`.${startPageClasses.bgLines}`]: {
      position: 'absolute',
      top: 0,
      right: 0,
      ['& > svg']: {
        width: 600,
      },
    },
    [`.${startPageClasses.bgText}`]: {
      position: 'absolute',
      bottom: 32,
      left: 51,
      fontFamily: "'JetBrains Mono', monospace",
      fontWeight: 400,
      fontSize: spacing(4),
      color: '#1d2a37',
      textTransform: 'uppercase',
    },
  },

  [`.${startPageClasses.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    position: 'relative',
    padding: spacing(2),
  },
  [`.${startPageClasses.menuContainer}`]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: spacing(4),
    [breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  [`.${startPageClasses.menu}`]: {
    borderColor: palette.mapBlue[700],
    borderStyle: 'solid',
    borderWidth: spacing(0.5),
    borderRadius: spacing(3, 0.5),
    zIndex: 1,
    // padding: spacing(4),
    [`.${startPageClasses.menuHeader}`]: {
      backgroundColor: palette.mapBlue[700],
      borderRadius: spacing(2.5, 0),
      fontSize: spacing(3),
      padding: spacing(1, 3),
      display: 'flex',
      whiteSpace: 'nowrap',
      gap: spacing(2),
      ['& > svg']: {
        width: spacing(4),
        maxWidth: spacing(4),
        maxHeight: spacing(4),
        // filter: 'grayscale(1)',
        filter: 'grayscale(1) brightness(0) invert(1)',
      },
    },
    [`.${startPageClasses.menuContent}`]: {
      padding: spacing(2, 2),
      [`.${listClasses.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(2),
        [`.${listItemClasses.root}`]: {
          padding: spacing(0, 2),
          transition: 'background-color 0.5s ease-in-out',
          // backgroundColor: 'transparent',
          fontSize: spacing(3),
          borderRadius: spacing(3, 0.5),
          borderStyle: 'solid',
          borderWidth: 2,
          borderColor: palette.mapBlue[700],
          backgroundColor: palette.mapBlue[900],
          ['&:hover']: {
            backgroundColor: palette.mapBlue[700],
          },
          [`.${linkClasses.root}`]: {
            whiteSpace: 'nowrap',
            height: spacing(6),
            color: palette.grey[200],
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            padding: 0,
            boxSizing: 'border-box',
            gap: spacing(2),
            cursor: 'pointer',
            textDecoration: 'none',
            fontSize: spacing(3),
            [`svg`]: {
              width: '100%',
              maxWidth: spacing(4),
              maxHeight: spacing(4),
              color: palette.grey[200],
              fill: 'currentColor',
            },
          },
        },
      },
    },
  },
  [`.${startPageClasses.logoutLink}`]: {
    padding: spacing(0, 2),
    transition: 'background-color 0.5s ease-in-out',
    // backgroundColor: 'transparent',
    fontSize: spacing(3),
    borderRadius: spacing(3, 0.5),
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: palette.mapBlue[700],
    backgroundColor: palette.mapBlue[900],
    alignSelf: 'end',
    minWidth: 170,
    ['&:hover']: {
      backgroundColor: palette.mapBlue[700],
    },
    [`.${linkClasses.root}`]: {
      whiteSpace: 'nowrap',
      height: spacing(6),
      color: palette.grey[200],
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      padding: 0,
      boxSizing: 'border-box',
      gap: spacing(2),
      cursor: 'pointer',
      textDecoration: 'none',
      fontSize: spacing(3),
      [`svg`]: {
        width: '100%',
        maxWidth: spacing(4),
        maxHeight: spacing(4),
        color: palette.grey[200],
        fill: 'currentColor',
      },
    },
  },
}));

export const StartPage = () => {
  const { status, grantedGroups } = useSsoUserGroupAccess();

  return status !== 'pending' ? (
    <Root className={startPageClasses.root}>
      <Box className={startPageClasses.bg}>
        <Box className={startPageClasses.bgLines}>
          <ComboPageBgSVG />
        </Box>
      </Box>
      <Box className={startPageClasses.container}>
        <Box className={startPageClasses.menuContainer}>
          {grantedGroups.includes('Admin') ||
          grantedGroups.includes('CommandCenter') ? (
            <Box className={startPageClasses.menu}>
              <Box className={startPageClasses.menuHeader}>
                <TransmedicsLogoSVG />
                TMDX Command Center
              </Box>
              <Box className={startPageClasses.menuContent}>
                <List className={startPageClasses.menuList}>
                  <ListItem>
                    <Link component={NavLink} to={routes.MAIN_MAP}>
                      <MainMapSVG />
                      Active Cases Map
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.COMBO_PAGE}>
                      <ComboPageSVG />
                      Combo Screen
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.AIRCRAFTS_MAP}>
                      <PlaneMenuSVG />
                      Air Fleet Tracker
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.AIRCRAFTS_CARDS}>
                      <PlannerMenuSVG />
                      Air Fleet Planner
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.ERRORS}>
                      <MonitorMenuSVG />
                      System Monitor
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATISTICS}>
                      <StatisticsSVG />
                      Statistics
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.HUBS_REPORT}>
                      <HubSVG />
                      Hubs Report
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.WEATHER}>
                      <WeatherSVG />
                      Aviation Weather
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Box>
          ) : null}
          {grantedGroups.includes('Admin') ||
          grantedGroups.includes('Statistics') ? (
            <Box className={startPageClasses.menu}>
              <Box className={startPageClasses.menuHeader}>
                <StatsLogoSVG />
                TMDX Aviation Maintenance
              </Box>
              <Box className={startPageClasses.menuContent}>
                <List className={startPageClasses.menuList}>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATS_FR}>
                      <UpdateIcon />
                      AOG Frequency
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATS_UPTIME}>
                      <MoreTimeIcon />
                      Uptime
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATS_DOWNTIME}>
                      <RunningWithErrorsIcon />
                      Downtime
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATS_MTTR}>
                      <ManageHistoryIcon />
                      MTTR & AOG Duration
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATS_MTBA}>
                      <HistoryToggleOffIcon />
                      MTBA
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link component={NavLink} to={routes.STATS_AOGDR}>
                      <ScheduleSendIcon />
                      AOG Dispatch Reliability
                    </Link>
                  </ListItem>
                </List>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className={startPageClasses.logoutLink}>
          <Link component={NavLink} to={routes.LOGOUT}>
            <LogoutIcon />
            <Box>Logout</Box>
          </Link>
        </Box>
      </Box>
    </Root>
  ) : (
    <Loader />
  );
};
