import { loginRequest } from '../authConfig';
import { REACT_APP_SSO_REDIRECT_URL } from '../const/const';
import { msalInstance } from '../index';

const handleMsalError = (e: unknown) => {
  const errorString = (e as Error).toString();
  const isSsoError =
    errorString.includes('Correlation ID') ||
    errorString.includes('BrowserAuthError');
  if (isSsoError) {
    return 'CCS_MSAL. Error.';
  }
  throw Error('CCS_MSAL. Some Network Error.');
};

const getActiveMsalAccount = () => {
  const account = msalInstance.getActiveAccount();

  if (account) {
    return account;
  }
  throw Error(
    'CCS_MSAL. No active account! Verify a user has been signed in and setActiveAccount has been called.',
  );
};

const acquireMsalToken = async (args: { forceRefresh: boolean }) =>
  msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: getActiveMsalAccount(),
    forceRefresh: args.forceRefresh,
    redirectUri: REACT_APP_SSO_REDIRECT_URL,
  });

const tryFetchWithMsalHeaders = async (args: {
  url: string;
  forceRefresh: boolean;
}) => {
  const msalTokenResponse = await acquireMsalToken({
    forceRefresh: args.forceRefresh,
  });
  const msalHeaders = {
    Authorization: `Bearer ${msalTokenResponse.accessToken}`,
  };
  return fetch(args.url, { headers: msalHeaders });
};

export const fetchWithMsalHeaders = async (
  url: string,
): Promise<[Response, null] | [null, string]> => {
  let msalError;

  try {
    return [await tryFetchWithMsalHeaders({ url, forceRefresh: false }), null];
  } catch (e) {
    console.info(
      'CCS_MSAL. Check Auth token without refresh:',
      (e as Error).toString(),
    );
  }

  try {
    return [await tryFetchWithMsalHeaders({ url, forceRefresh: true }), null];
  } catch (e) {
    console.info(
      'CCS_MSAL. Check Auth token with refresh:',
      (e as Error).toString(),
    );
    // if error comes from SSO, set it. If not - Throw new Error
    msalError = handleMsalError(e);
  }

  return [null, msalError];
};
