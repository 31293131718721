import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useQueryStatsUptime } from '../../../../adapters';
import {
  statsFormatHours,
  statsFormatPercent,
  statsFormatRate,
} from '../../stat-constants';
import { StatsIntervalCell } from '../StatsIntervalCell';
import { StatsTable, type StatsTableRow } from '../StatsTable';
import { useQueryData } from '../useQueryData';

type TableRowUptime = StatsTableRow<{
  uptimeHours: number;
  totalHours: number;
}>;

export const StatsUptimeTable = () => {
  const { data: statsData } = useQueryData(useQueryStatsUptime);

  const {
    intervalColumns,
    rows,
  }: {
    intervalColumns: GridColDef[];
    rows: TableRowUptime[];
  } = useMemo(() => {
    const defaultResult = {
      intervalColumns: [],
      rows: [],
    };
    if (!statsData) {
      return defaultResult;
    }
    const aircraftsStats = Object.values(statsData?.perAircraft);
    if (!aircraftsStats.length) {
      return defaultResult;
    }

    const intervalFields = Object.keys(statsData.perDate);
    const intervalColumns: GridColDef[] = intervalFields.map(date => ({
      field: date,
      headerName: dayjs(date).format('DD.MM.YYYY'),
      minWidth: 120,
      type: 'custom',
      align: 'right',
      renderCell: ({ value }: GridRenderCellParams) => (
        <StatsIntervalCell value={value} />
      ),
    }));

    const rows: TableRowUptime[] = Object.values(statsData.perAircraft).map(
      ({
        tailNumber,
        uptimeHours,
        totalValue,
        uptimeRate,
        uptimeRatePrev,
        intervals,
      }) => {
        const intervalValues = keyBy(
          intervalFields.map(date => ({
            date,
            uptimeHours:
              intervals[date]?.totalValue - intervals[date]?.downtimeValue,
            totalHours: intervals[date]?.totalValue ?? 0,
          })),
          'date',
        );

        return {
          id: tailNumber,
          tailNumber,
          uptimeHours,
          totalHours: totalValue,
          rate: [uptimeRate, uptimeRatePrev] as [number, number],
          intervals: intervalValues,
        };
      },
    );
    return { rows, intervalColumns };
  }, [statsData]);

  return (
    <StatsTable
      rows={rows}
      rateField={{ valueFormatter: statsFormatRate }}
      intervalField={{
        field: 'uptimeHours',
        valueFormatter: statsFormatHours,
      }}
      intervalSecondaryField={{
        field: 'totalHours',
        valueFormatter: statsFormatHours,
      }}
      summaryColumns={[
        {
          field: 'uptimeHours',
          label: 'Uptime Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'totalHours',
          label: 'Total Hours',
          valueFormatter: statsFormatHours,
        },
        {
          field: 'rate',
          label: 'Uptime Rate',
          valueFormatter: statsFormatPercent,
        },
      ]}
      intervalColumns={intervalColumns}
      intervalGroupTitle="Uptime Hours / Total Hours"
      rateStrategy="asc"
    />
  );
};
