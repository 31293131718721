import { Routes, Route } from 'react-router-dom';

import { allRoutes as routes } from '../const/routes';
import { MapProvider } from '../contexts/MapContext';
import { WeatherProvider } from '../contexts/WeatherContext';
import { NoAccessPage, StatisticsPage, WeatherPage } from '../pages';
import { AircraftCardsPage } from '../pages/AircraftCardsPage';
import { AircraftsMapPage } from '../pages/AircraftsMapPage';
import { ComboPage } from '../pages/ComboPage';
import { ErrorsPage } from '../pages/ErrorsPage';
import { HubsReportPage } from '../pages/HubsReportPage';
import { LogoutPage } from '../pages/LogoutPage';
import { MainMapPage } from '../pages/MainMapPage';
import { PageNotFound } from '../pages/PageNotFound';
import { StartPage } from '../pages/StartPage';
import { StatsPage } from '../pages/StatsPage';

import { RouteGuard } from './enhancers';

export const AppRouter = () => (
  <Routes>
    <Route
      path={routes.START_PAGE}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <StartPage />
        </RouteGuard>
      }
    />
    <Route
      path={routes.MAIN_MAP}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <MapProvider>
            <MainMapPage />
          </MapProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.ERRORS}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <MapProvider>
            <ErrorsPage />
          </MapProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.STATISTICS}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <MapProvider>
            <StatisticsPage />
          </MapProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.COMBO_PAGE}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <WeatherProvider>
            <MapProvider>
              <ComboPage />
            </MapProvider>
          </WeatherProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.AIRCRAFTS_MAP}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <MapProvider>
            <AircraftsMapPage />
          </MapProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.AIRCRAFTS_CARDS}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <MapProvider>
            <AircraftCardsPage />
          </MapProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.HUBS_REPORT}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <MapProvider>
            <HubsReportPage />
          </MapProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.WEATHER}
      element={
        <RouteGuard ssoUserGroups={['CommandCenter']}>
          <WeatherProvider>
            <MapProvider>
              <WeatherPage />
            </MapProvider>
          </WeatherProvider>
        </RouteGuard>
      }
    />
    <Route
      path={routes.STATS}
      element={
        <RouteGuard ssoUserGroups={['Statistics']}>
          <StatsPage />
        </RouteGuard>
      }
    />
    <Route
      path={routes.LOGOUT}
      element={
        <RouteGuard>
          <LogoutPage />
        </RouteGuard>
      }
    />
    <Route
      path={routes.NO_ACCESS}
      element={
        <RouteGuard>
          <NoAccessPage />
        </RouteGuard>
      }
    />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);
