import './sentry.setup';
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from '@azure/msal-browser';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { msalConfig } from './authConfig';

import 'jetbrains-mono';
import './index.css';

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  const eventsArr: EventType[] = [
    EventType.LOGIN_SUCCESS,
    EventType.ACQUIRE_TOKEN_SUCCESS,
    EventType.SSO_SILENT_SUCCESS,
  ];
  const isNeededEvent = eventsArr.includes(event.eventType);

  if (isNeededEvent && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <App msalInstance={msalInstance} />
    </BrowserRouter>
  </StrictMode>,
);
